<template>
    <el-main>
        <el-tabs type="border-card">
            <el-tab-pane label="元数据">
                <el-table
                        :data="metadata.metas?(metadata.metas.filter(meta => !metaSearch
                            || meta.name.toLowerCase().includes(metaSearch.toLowerCase())
                            || meta.label.toLowerCase().includes(metaSearch.toLowerCase()))): []"
                        style="width: 100%">
                    <el-table-column
                            label="标签"
                            prop="label">
                    </el-table-column>
                    <el-table-column
                            label="属性"
                            prop="name">
                    </el-table-column>
                    <el-table-column
                            label="类型"
                            prop="type">
                        <template slot-scope="scope">
                            {{Search_Type_Label[scope.row.type]}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="支持表格">
                        <template slot-scope="scope">
                            <i v-if="scope.row.supportTable" class="el-icon-check" style="color: #67C23A"></i>
                            <i v-else class="el-icon-close" style="color: #F56C6C"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="支持搜索">
                        <template slot-scope="scope">
                            <i v-if="scope.row.supportSearch" class="el-icon-check" style="color: #67C23A"></i>
                            <i v-else class="el-icon-close" style="color: #F56C6C"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="支持表单">
                        <template slot-scope="scope">
                            <i v-if="scope.row.supportForm" class="el-icon-check" style="color: #67C23A"></i>
                            <i v-else class="el-icon-close" style="color: #F56C6C"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="支持详细">
                        <template slot-scope="scope">
                            <i v-if="scope.row.supportDetail" class="el-icon-check" style="color: #67C23A"></i>
                            <i v-else class="el-icon-close" style="color: #F56C6C"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="right" min-width="150">
                        <template slot="header" slot-scope="scope">
<!--                            <el-input-->
<!--                                    v-model="metaSearch"-->
<!--                                    size="mini"-->
<!--                                    placeholder="输入关键字搜索" />-->
                            <el-button
                                    size="mini"
                                    @click="emptyMeta(null)" style="">清空</el-button>
                            <el-button
                            <el-button
                                    size="mini"
                                    @click="copyMeta(null)" style="">复制</el-button>
                            <el-button
                                    size="mini"
                                    @click="importMeta(null)" style="">导入</el-button>
                            <el-button
                                    size="mini"
                                    @click="openMeta(null)" style="">新增</el-button>
                        </template>
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="openMeta(scope.row)">编辑</el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteMetaHandle(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="表格配置">
                <el-table
                        :data="tableData"
                        style="width: 100%" ref="tabletable">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            label="元标签"
                            prop="label">
                    </el-table-column>
                    <el-table-column
                            label="属性"
                            prop="name">
                    </el-table-column>
                    <el-table-column
                            label="显示标签"
                            prop="alias">
                    </el-table-column>
                    <el-table-column
                            min-width="150" align="right">
                        <template slot="header" slot-scope="scope">
                                <el-checkbox v-model="metadata.tableOptions.open">功能开启</el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text" @click="openTableItem(scope.row)">
                                修改 <i class="el-icon-edit"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('tabletable', scope.row.id,'up')">
                                上移 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('tabletable', scope.row.id,'down')">
                                下移 <i class="el-icon-arrow-down"></i>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="搜索栏配置">
                <el-table
                        :data="searchData"
                        style="width: 100%" ref="searchtable">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            label="标签">
                        <template slot-scope="scope">
                            {{scope.row.label}}{{scope.row.default?'(默认)':''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="属性"
                            prop="name">
                    </el-table-column>
                    <el-table-column
                            label="类型"
                            prop="type">
                    </el-table-column>
                    <el-table-column align="right"
                            min-width="250">
                        <template slot="header" slot-scope="scope">
                            <el-checkbox v-model="metadata.searchOptions.open">功能开启</el-checkbox>
                            <el-button size="mini" @click="openSearchItem(null)" >新增</el-button>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text" @click="tableItemMove('searchtable', scope.row.id,'up')">
                                上移 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('searchtable', scope.row.id,'down')">
                                下移 <i class="el-icon-arrow-down"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="openSearchItem(scope.row)">
                                修改 <i class="el-icon-edit"></i>
                            </el-button>
                            <template v-if="scope.row.id>1000">
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="deleteSearchItemHandle(scope.row)">
                                    删除 <i class="el-icon-edit"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="表单配置">
                <el-table
                        :data="formData"
                        style="width: 100%" ref="formtable">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            label="元标签"
                            prop="label">
                    </el-table-column>
                    <el-table-column
                            label="属性"
                            prop="name">
                    </el-table-column>
                    <el-table-column
                            label="显示标签"
                            prop="alias">
                    </el-table-column>
                    <el-table-column
                            min-width="150" align="right">
                        <template slot="header" slot-scope="scope">
                            <el-checkbox v-model="metadata.formOptions.open">功能开启</el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text" @click="openFormItem(scope.row)">
                                修改 <i class="el-icon-edit"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('formtable', scope.row.id,'up')">
                                上移 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('formtable', scope.row.id,'down')">
                                下移 <i class="el-icon-arrow-down"></i>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="详细配置">
                <el-table
                        :data="detailData"
                        style="width: 100%" ref="detailtable">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            label="元标签"
                            prop="label">
                    </el-table-column>
                    <el-table-column
                            label="属性"
                            prop="name">
                    </el-table-column>
                    <el-table-column
                            label="类型"
                            prop="type">
                    </el-table-column>
                    <el-table-column
                            label="显示标签"
                            prop="alias">
                    </el-table-column>
                    <el-table-column align="right"
                                     min-width="250">
                        <template slot="header" slot-scope="scope">
                            <el-checkbox v-model="metadata.detailOptions.open">功能开启</el-checkbox>
                            <el-button size="mini" @click="openDetailItem(null)" >新增</el-button>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text" @click="tableItemMove('detailtable', scope.row.id,'up')">
                                上移 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="tableItemMove('detailtable', scope.row.id,'down')">
                                下移 <i class="el-icon-arrow-down"></i>
                            </el-button>
                            <el-divider direction="vertical"></el-divider>
                            <el-button type="text" @click="openDetailItem(scope.row)">
                                修改 <i class="el-icon-edit"></i>
                            </el-button>
                            <template v-if="scope.row.id>1000">
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="deleteDetailItemHandle(scope.row)">
                                    删除 <i class="el-icon-edit"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-dialog :title="metaForm.id?'修改元数据':'新增元数据'" :visible.sync="metaFormVisible" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form :model="metaForm" :rules="rules.meta" label-width="100px" ref="metaForm">
                <el-form-item label="标签" prop="label">
                    <el-input v-model="metaForm.label" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="属性" prop="name">
                    <el-input v-model="metaForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="metaForm.type">
                        <el-option v-for="(label, value, index) in Search_Type_Label" :key="index" :value="value" :label="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型明细" v-if="metaForm.type && metaForm.type === Search_Type.Select">
                    <el-input type="textarea" v-model="metaProps" placeholder='Json数据:{"1":"正常","2":"异常"}' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型颜色" v-if="metaForm.type && metaForm.type === Search_Type.Select">
                    <el-input type="textarea" v-model="metaPropsColors" placeholder='Json数据:{"1":"primary","2":"info"}' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="下拉框初始值" v-if="metaForm.type && metaForm.type === Search_Type.Select">
                    <el-input type="textarea" v-model="metaForm.defaultValue" placeholder='下拉框初始值(以key为主)' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="动态Url" v-if="metaForm.type && metaForm.type === Search_Type.DynamicSelect" prop="dynamicUrl">
                    <el-input v-model="metaForm.dynamicUrl" placeholder='填写url,如/option/values' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="动态参数" v-if="metaForm.type && metaForm.type === Search_Type.DynamicSelect" prop="dynamicParams">
                    <el-input v-model="metaForm.dynamicParams" placeholder='填写key,value动态参数,如id,name' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="支持表格">
                    <el-checkbox v-model="metaForm.supportTable">开启</el-checkbox>
                </el-form-item>
                <el-form-item label="支持搜索">
                    <el-checkbox v-model="metaForm.supportSearch">开启</el-checkbox>
                </el-form-item>
                <el-form-item label="支持表单">
                    <el-checkbox v-model="metaForm.supportForm">开启</el-checkbox>
                </el-form-item>
                <el-form-item label="支持详细">
                    <el-checkbox v-model="metaForm.supportDetail">开启</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="metaFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveMetaHandle('metaForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改表格数据" :visible.sync="tableFormVisible" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form :model="tableForm" :rules="rules.table" label-width="100px" ref="tableForm">
                <el-form-item label="元标签(类型)">
                    <el-input :value="tableForm.label+'('+tableForm.type+')'" disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型明细" v-if="tableForm.type && tableForm.type === Search_Type.Select">
                    <el-input type="textarea" disabled v-model="metaProps" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示标签" prop="alias">
                    <el-input v-model="tableForm.alias" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="最小宽度" prop="minWidth">
                    <el-input v-model="tableForm.minWidth" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="tableFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveTableItemHandle('tableForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="searchForm.id?'修改搜索栏':'新增搜索栏'+'(元数据无法修改)'" :visible.sync="searchFormVisible" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form :model="searchForm" :rules="rules.search" label-width="100px" ref="searchForm">
                <el-form-item label="标签" prop="label">
                    <el-input v-model="searchForm.label" :disabled="searchForm.id && searchForm.id<1000" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="属性" prop="name">
                    <el-input v-model="searchForm.name" :disabled="searchForm.id && searchForm.id<1000" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="searchForm.type" :disabled="searchForm.id && searchForm.id<1000">
                        <el-option v-for="(label, value, index) in Search_Type_Label" :key="index" :value="value" :label="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型明细" v-if="searchForm.type && searchForm.type === Search_Type.Select">
                    <el-input type="textarea" :disabled="searchForm.id && searchForm.id<1000" v-model="metaProps" placeholder='Json数据:{"1":"正常","2":"异常"}' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="下拉框初始值" v-if="searchForm.type && searchForm.type === Search_Type.Select">
                    <el-input type="textarea" :disabled="searchForm.id && searchForm.id<1000" v-model="searchForm.defaultValue" placeholder='下拉框初始值(以key为主)' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="默认选中">
                    <el-radio-group v-model="searchForm.default">
                        <el-radio :label="false">否</el-radio>
                        <el-radio :label="true">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="searchFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSearchItemHandle('searchForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改表单数据" :visible.sync="formFormVisible" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form :model="formForm" :rules="rules.form" label-width="100px" ref="formForm">
                <el-form-item label="元标签(类型)">
                    <el-input :value="formForm.label+'('+formForm.type+')'" disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型明细" v-if="formForm.type && formForm.type === Search_Type.Select">
                    <el-input type="textarea" disabled v-model="metaProps" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="下拉框初始值" v-if="formForm.type && formForm.type === Search_Type.Select">
                    <el-input type="textarea" disabled v-model="formForm.defaultValue" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="动态Url" v-if="formForm.type && formForm.type === Search_Type.DynamicSelect">
                    <el-input v-model="formForm.dynamicUrl" disabled placeholder='填写url,如/option/values' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="动态参数" v-if="formForm.type && formForm.type === Search_Type.DynamicSelect">
                    <el-input v-model="formForm.dynamicParams" disabled placeholder='填写key,value动态参数,如id,name' autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示标签" prop="alias">
                    <el-input v-model="formForm.alias" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="修改时只读">
                    <el-checkbox v-model="formForm.readonly">开启</el-checkbox>
                </el-form-item>
                <el-form-item label="校验规则">
                    <el-select v-model="formForm.validate.type" clearable placeholder="类型校验">
                        <el-option v-for="(v,k,index) in Validator_Type" :key="index" :label="Validator_Type_Detail[v].label" :value="v" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  v-if="formForm.validate.type && formForm.validate.type!=Validator_Type.Phone" label="校验长度" >
                    <el-input v-model="formForm.validate.min" style="width: 100px" placeholder="最小长度" autocomplete="off"></el-input>
                    ---
                    <el-input v-model="formForm.validate.max" style="width: 100px" placeholder="最大长度" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="formFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveFormItemHandle('formForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="detailForm.id?'修改详细栏':'新增详细栏'+'(元数据无法修改)'" :visible.sync="detailFormVisible" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form :model="detailForm" :rules="rules.detail" label-width="100px" ref="detailForm">
                <el-form-item label="初始标签" prop="label">
                    <el-input v-model="detailForm.label" :disabled="detailForm.id && detailForm.id<1000" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示标签" prop="alias">
                    <el-input v-model="detailForm.alias" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="属性" prop="name">
                    <el-input v-model="detailForm.name" :disabled="detailForm.id && detailForm.id<1000" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="detailForm.type" :disabled="detailForm.id && detailForm.id<1000">
                        <el-option v-for="(label, value, index) in Search_Type_Label" :key="index" :value="value" :label="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型明细" v-if="detailForm.type && detailForm.type === Search_Type.Select">
                    <el-input type="textarea" :disabled="detailForm.id && detailForm.id<1000" v-model="metaProps" placeholder='Json数据:{"1":"正常","2":"异常"}' autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="detailFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveDetailItemHandle('detailForm')">确 定</el-button>
            </div>
        </el-dialog>

        <div class="dialog-footer" style="text-align: right;padding: 30px">
            <el-button @click="$emit('cancel')">取 消</el-button>
            <el-button type="primary" @click="saveMetadataHandle">保 存</el-button>
        </div>
    </el-main>
</template>

<script>
    import {Search_Type,Search_Type_Label, PickerOptions, Validator_Type, Validator_Type_Detail} from '../../constants';
    export default {
        props:['outMetadata'],
        data(){
            let validateMinWidth = (rule, value, callback) => {
                if (!value) {
                    callback();
                } else if (!/^[0-9]+$/.test(value)) {
                    callback(new Error('最小宽度不是数字'));
                } else {
                    callback();
                }
            };
            return {
                metadata: {...this.outMetadata},
                metaSearch: '',
                metaProps: '',
                metaPropsColors: '',
                metaForm: {},
                metaFormVisible: false,
                tableData: [],
                tableForm: {},
                tableFormVisible: false,
                searchData: [],
                searchForm: {},
                searchFormVisible: false,
                formData: [],
                formForm: {
                    validate:{}
                },
                formFormVisible: false,
                detailData: [],
                detailForm: {},
                detailFormVisible: false,
                rules: {
                    meta: {
                        label: [
                            { required: true, message: '请填写标签(中英文)', trigger: 'blur' }
                        ],
                        name: [
                            { required: true, message: '请填写属性名(英文)', trigger: 'blur' },
                            { pattern: /^\w+[\\.]?\w+$/, message: "属性名只能是数字字母下划线", trigger: 'blur' }
                        ],
                        type: [
                            { required: true, message: '请选择类型', trigger: 'blur' }
                        ],
                        dynamicUrl: [
                            { required: true, message: '请填写动态地址', trigger: 'blur' }
                        ],
                        dynamicParams: [
                            { required: true, message: '请填写动态参数', trigger: 'blur' }
                        ],
                    },
                    table: {
                        minWidth: [
                            {validator: validateMinWidth}
                            // { required: true, message: '请填写最小宽度(0代表不设置)', trigger: 'blur' },
                            // { pattern: /^[0-9]+$/, message: "最小宽度只能是数字", trigger: 'blur' }
                        ]
                    },
                    search: {
                        label: [
                            { required: true, message: '请填写标签(中英文)', trigger: 'blur' }
                        ],
                        name: [
                            { required: true, message: '请填写属性名(英文)', trigger: 'blur' },
                            { pattern: /^\w+$/, message: "属性名只能是数字字母下划线", trigger: 'blur' }
                        ],
                        type: [
                            { required: true, message: '请选择类型', trigger: 'blur' }
                        ],
                    },
                    form: {

                    },
                    detail: {
                        label: [
                            { required: true, message: '请填写标签(中英文)', trigger: 'blur' }
                        ],
                        name: [
                            { required: true, message: '请填写属性名(英文)', trigger: 'blur' },
                            { pattern: /^\w+[\\.]?\w+$/, message: "属性名只能是数字字母下划线", trigger: 'blur' }
                        ],
                        type: [
                            { required: true, message: '请选择类型', trigger: 'blur' }
                        ],
                    },
                },
                Search_Type,
                PickerOptions,
                Search_Type_Label,
                Validator_Type,
                Validator_Type_Detail
            }
        },
        methods:{
            saveMetadataHandle(){
                let tableSelectIds =  this.$refs.tabletable.selection.map(row=>row.id)
                this.metadata.tableOptions.items = this.tableData.filter(row=>tableSelectIds.includes(row.id)).map(row=>({
                    id: row.id,
                    alias: row.alias,
                    minWidth: row.minWidth
                }))
                let searchSelectIds = this.$refs.searchtable.selection.map(row=>row.id)
                this.metadata.searchOptions.items = this.searchData.filter(row=>searchSelectIds.includes(row.id)).map(row=>{
                    if(row.id<1000){
                        return {id: row.id, default: row.default}
                    }else{
                        return this.g_deepClone(row)
                    }
                })
                let formSelectIds = this.$refs.formtable.selection.map(row=>row.id)
                this.metadata.formOptions.items = this.formData.filter(row=>formSelectIds.includes(row.id)).map(row=>({
                    id: row.id,
                    alias: row.alias,
                    readonly: row.readonly,
                    validate: row.validate,
                }))
                let detailSelectIds = this.$refs.detailtable.selection.map(row=>row.id)
                this.metadata.detailOptions.items = this.detailData.filter(row=>detailSelectIds.includes(row.id)).map(row=>{
                    if(row.id<1000){
                        return {id: row.id}
                    }else{
                        return this.g_deepClone(row)
                    }
                })
                this.$emit('save', this.metadata)
            },
            emptyMeta(){
                this.metadata = {
                    metas: [],
                    tableOptions: {},
                    formOptions: {},
                    searchOptions: {},
                    detailOptions: {},
                }
            },
            copyMeta(){
                let textArea = document.createElement("textarea");
                textArea.value = JSON.stringify(this.metadata);
                // make the textarea out of viewport
                textArea.style.position = "fixed";
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // here the magic happens
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                    this.$message.success("复制成功")
                });
            },
            importMeta(){
                this.$prompt('请粘贴导入的Json字符串', '元数据导入', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: '{"metas":[]}',
                    inputValidator: (str)=>{
                        try {
                            if(typeof JSON.parse(str) === 'object')
                                return true;
                        }catch (e) {
                            
                        }
                        return '格式不正确';
                    },
                    closeOnClickModal: false,
                }).then(({ value }) => {
                    this.metadata = JSON.parse(value)
                })
            },
            openMeta(meta){
                if(meta){
                    if(meta.supportTable === undefined){
                        this.metaForm = {
                            ...meta,
                            supportTable: false,
                            supportSearch: false,
                            supportForm: false,
                            supportDetail: false,
                        }
                    }else{
                        this.metaForm = {...meta}
                    }
                }else{
                    this.metaForm = {
                        supportTable: true,
                        supportSearch: true,
                        supportForm: true,
                        supportDetail: true,
                    }
                }
                // console.log(meta)
                // console.log(this.metaForm)
                if(this.metaForm.props){
                    this.metaProps = JSON.stringify(this.metaForm.props)
                }
                if(this.metaForm.propsColors){
                    this.metaPropsColors = JSON.stringify(this.metaForm.propsColors)
                }
                this.metaFormVisible = true
            },
            openTableItem(item){
                this.tableForm = {...item}
                if(this.tableForm.props){
                    this.metaProps = JSON.stringify(this.tableForm.props)
                }
                this.tableFormVisible = true
            },
            openSearchItem(item){
                if(item){
                    this.searchForm = {...item}
                }else{
                    this.searchForm = {}
                }
                if(this.searchForm.props){
                    this.metaProps = JSON.stringify(this.searchForm.props)
                }
                this.searchFormVisible = true
            },
            openFormItem(item){
                if(item.validate){
                    this.formForm = {...item}
                }else{
                    this.formForm = {...item, validate:{}}
                }
                if(this.formForm.props){
                    this.metaProps = JSON.stringify(this.formForm.props)
                }
                this.formFormVisible = true
            },
            openDetailItem(item){
                if(item){
                    this.detailForm = {...item}
                }else{
                    this.detailForm = {}
                }
                if(this.detailForm.props){
                    this.metaProps = JSON.stringify(this.detailForm.props)
                }
                this.detailFormVisible = true
            },
            deleteMetaHandle(row){
                this.metadata.metas = this.metadata.metas.filter(meta=>meta.id!=row.id)
            },
            deleteSearchItemHandle(row){
                this.searchData = this.searchData.filter(item=>item.id!=row.id)
            },
            deleteDetailItemHandle(row){
                this.detailData = this.detailData.filter(item=>item.id!=row.id)
            },
            saveMetaHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.metaForm.type === Search_Type.Select){
                            try {
                                this.metaForm.props = JSON.parse(this.metaProps)
                            }catch (e) {
                                this.$message.error("类型明细不是合理的Json数据")
                                return;
                            }
                            if(this.metaPropsColors){
                                try {
                                    this.metaForm.propsColors = JSON.parse(this.metaPropsColors)
                                }catch (e) {
                                    this.$message.error("类型颜色不是合理的Json数据")
                                    return;
                                }
                            }
                        }
                        if(this.metaForm.id){
                            if(this.metadata.metas.filter(meta=>(meta.name===this.metaForm.name)&&(meta.id!=this.metaForm.id)).length>0){
                                this.$message.error(`存在同名属性${this.metaForm.name}`)
                                return ;
                            }
                            this.metadata.metas = this.metadata.metas.map(meta=>meta.id===this.metaForm.id?this.g_deepClone(this.metaForm):meta)
                        }else{
                            if(this.metadata.metas.filter(meta=>meta.name === this.metaForm.name).length>0){
                                this.$message.error(`存在同名属性${this.metaForm.name}`)
                                return ;
                            }
                            let max = 0
                            max = Math.max(max, ...this.metadata.metas.map(meta=>meta.id))
                            this.metaForm.id = max+1
                            this.metadata.metas.push(this.g_deepClone(this.metaForm))
                        }
                        this.metaFormVisible = false;
                    } else {
                        this.$message.error("校验失败")
                    }
                })
            },
            saveTableItemHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.tableData = this.tableData.map(row=>row.id===this.tableForm.id?this.g_deepClone(this.tableForm):row)
                        this.tableFormVisible = false
                    } else {
                        this.$message.error("校验失败")
                    }
                })
            },
            saveSearchItemHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.searchForm.type === Search_Type.Select){
                            try {
                                this.searchForm.props = JSON.parse(this.metaProps)
                            }catch (e) {
                                this.$message.error("类型明细不是合理的Json数据")
                                return;
                            }
                        }
                        if(this.searchForm.id){
                            if(this.searchForm.id>1000 && this.searchData.filter(item=>(item.id>1000)&&(item.name===this.searchForm.name)&&(item.id!=this.searchForm.id)).length>0){
                                this.$message.error(`存在同名属性${this.searchForm.name}`)
                                return ;
                            }
                            this.searchData = this.searchData.map(item=>item.id===this.searchForm.id?this.g_deepClone(this.searchForm):item)
                        }else{
                            if(this.searchData.filter(item=>(item.id>1000) && (item.name===this.searchForm.name)).length>0){
                                this.$message.error(`存在同名属性${this.searchForm.name}`)
                                return ;
                            }
                            //自建查询条件id > 1000
                            let max = 1000
                            max = Math.max(max, ...this.searchData.map(item=>item.id))
                            this.searchForm.id = max+1
                            this.searchData.push(this.g_deepClone(this.searchForm))
                        }
                        this.searchFormVisible = false;
                    } else {
                        this.$message.error("校验失败")
                    }
                })
            },
            saveFormItemHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.formForm.validate.type){
                            let min = this.formForm.validate.min
                            let max = this.formForm.validate.max
                            let minNumberReg = /[0-9]+/g;
                            let maxNumberReg = /[0-9]+/g;
                            if(min && !minNumberReg.test(min)){
                                this.$message.error("最小值不是整数")
                                return false
                            }
                            if(max && !maxNumberReg.test(max)){
                                this.$message.error("最大值不是整数")
                                return false
                            }
                        }
                        this.formData = this.formData.map(row=>row.id===this.formForm.id?this.g_deepClone(this.formForm):row)
                        this.formFormVisible = false
                    } else {
                        this.$message.error("校验失败")
                    }
                })
            },
            saveDetailItemHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.detailForm.type === Search_Type.Select){
                            try {
                                this.detailForm.props = JSON.parse(this.metaProps)
                            }catch (e) {
                                this.$message.error("类型明细不是合理的Json数据")
                                return;
                            }
                        }
                        if(this.detailForm.id){
                            if(this.detailForm.id>1000 && this.detailData.filter(item=>(item.id>1000)&&(item.name===this.detailForm.name)&&(item.id!=this.detailForm.id)).length>0){
                                this.$message.error(`存在同名属性${this.detailForm.name}`)
                                return ;
                            }
                            this.detailData = this.detailData.map(item=>item.id===this.detailForm.id?this.g_deepClone(this.detailForm):item)
                        }else{
                            if(this.detailData.filter(item=>(item.id>1000) && (item.name===this.detailForm.name)).length>0){
                                this.$message.error(`存在同名属性${this.detailForm.name}`)
                                return ;
                            }
                            //自建查询条件id > 1000
                            let max = 1000
                            max = Math.max(max, ...this.detailData.map(item=>item.id))
                            this.detailForm.id = max+1
                            this.detailData.push(this.g_deepClone(this.detailForm))
                        }
                        this.detailFormVisible = false;
                    } else {
                        this.$message.error("校验失败")
                    }
                })
            },
            setupTableOptions(){
                let tableOptions = this.metadata.tableOptions;
                if(!tableOptions || Object.keys(tableOptions).length === 0){
                    let newOptions = {
                        open: false,
                        items: []
                    }
                    this.metadata.tableOptions = newOptions
                }
                this.tableData = this.g_loadTableItems(this.metadata, true)
            },
            setupSearchOptions(){
                let searchOptions = this.metadata.searchOptions;
                if(!searchOptions || Object.keys(searchOptions).length === 0){
                    let newOptions = {
                        open: false,
                        items: []
                    }
                    this.metadata.searchOptions = newOptions
                }
                this.searchData = this.g_loadSearchItems(this.metadata, true)
            },
            setupFormOptions(){
                let formOptions = this.metadata.formOptions;
                if(!formOptions || Object.keys(formOptions).length === 0){
                    let newOptions = {
                        open: false,
                        items: []
                    }
                    this.metadata.formOptions = newOptions
                }
                this.formData = this.g_loadFormItems(this.metadata, true)
            },
            setupDetailOptions(){
                let detailOptions = this.metadata.detailOptions;
                if(!detailOptions || Object.keys(detailOptions).length === 0){
                    let newOptions = {
                        open: false,
                        items: []
                    }
                    this.metadata.detailOptions = newOptions
                }
                this.detailData = this.g_loadDetailItems(this.metadata, true)
            },
            tableItemMove(tableName, id, direction){
                let data = null
                if(tableName === 'tabletable'){
                    data = this.tableData
                }else if(tableName === 'searchtable'){
                    data = this.searchData
                }else if(tableName === 'formtable'){
                    data = this.formData
                }else if(tableName === 'detailtable'){
                    data = this.detailData
                }
                if(!data) return;
                let index = data.findIndex(item=>item.id === id)
                if(direction === 'up'){
                    if(index === 0) return;
                    const up = data[index-1]
                    data.splice(index-1 ,1)
                    data.splice(index, 0, up)
                }else if(direction === 'down'){
                    if(index === (data.length-1)) return;
                    const down = data[index+1]
                    data.splice(index+1, 1)
                    data.splice(index, 0, down)
                }
            },
            renderTableCheckbox(refName){
                if(refName === 'tabletable'){
                    let selectIds = this.metadata.tableOptions.items.map(item=>item.id)
                    this.$nextTick(() => {
                        this.tableData.forEach(row=>{
                            if(selectIds.indexOf(row.id)!=-1){
                                this.$refs.tabletable.toggleRowSelection(row , true)
                            }
                        })
                    })
                }else if(refName === 'searchtable'){
                    let selectIds = this.metadata.searchOptions.items.map(item=>item.id)
                    this.$nextTick(() => {
                        this.searchData.forEach(row=>{
                            if(selectIds.indexOf(row.id)!=-1){
                                this.$refs.searchtable.toggleRowSelection(row , true)
                            }
                        })
                    })
                }else if(refName === 'formtable'){
                    let selectIds = this.metadata.formOptions.items.map(item=>item.id)
                    this.$nextTick(() => {
                        this.formData.forEach(row=>{
                            if(selectIds.indexOf(row.id)!=-1){
                                this.$refs.formtable.toggleRowSelection(row , true)
                            }
                        })
                    })
                }else if(refName === 'detailtable'){
                    let selectIds = this.metadata.detailOptions.items.map(item=>item.id)
                    this.$nextTick(() => {
                        this.detailData.forEach(row=>{
                            if(selectIds.indexOf(row.id)!=-1){
                                this.$refs.detailtable.toggleRowSelection(row , true)
                            }
                        })
                    })
                }
            },
        },
        watch:{
            'metadata.metas': function (newVal, oldVal) {
                this.setupTableOptions();
                this.setupSearchOptions();
                this.setupFormOptions();
                this.setupDetailOptions();
            },
            tableData: function(newVal, oldVal){
                this.renderTableCheckbox('tabletable')
            },
            searchData: function(newVal, oldVal){
                this.renderTableCheckbox('searchtable')
            },
            formData: function (newVal, oldVal) {
                this.renderTableCheckbox('formtable')
            },
            detailData: function (newVal, oldVal) {
                this.renderTableCheckbox('detailtable')
            },
        },
        mounted() {
            this.$nextTick(function () {
                //init
                this.setupTableOptions()
                this.setupSearchOptions()
                this.setupFormOptions()
                this.setupDetailOptions()
            })
        }

    }
</script>

<style scoped>

</style>